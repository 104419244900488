<template>
  <b-card id="seccion-content">
    <b-card-header class="header-df">
      <b-col
        cols="12"
        md="12"
        class="pl-0"
      >
        <b-media vertical-align="center">
          <template #aside>
            <img
              src="/assets/img/d-graneleros.png"
              width="40"
            >
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            Distribución Graneleros
          </span>
          <small class="text-muted">Lista disbribución en Graneleros.</small>
        </b-media>
      </b-col>
    </b-card-header>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <template #cell(fechaOperacion)="data">
          <div>
            <div>
              <div class="font-small-3">
                <feather-icon icon="CalendarIcon" />
                {{ data.item.fechaOperacion }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(flota)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.flota.placa }}
            </span>
            <small class="text-muted d-block">{{ data.item.flota.marca }}</small>
            <small class="d-block font-weight-bold">{{ data.item.flota.capacidad }} GAL</small>
          </b-media>
        </template>
        <template #cell(ifa)="">
          <div>
            <div class="font-small-2 font-weight-bold">
              INICIO
            </div>
            <div class="font-small-2 font-weight-bold">
              FINAL
            </div>
            <div class="font-small-2 font-weight-bold">
              ABASTECE
            </div>
          </div>
        </template>
        <template #cell(porcInicial)="data">
          <div>
            <div class="font-small-3">
              {{ data.item.porcInicial }} %
            </div>
            <div class="font-small-3">
              {{ data.item.porcCantidadFinal }} %
            </div>
            <div class="font-small-4 font-weight-bold">
              {{ data.item.porcCantidadAbastece }} %
            </div>
          </div>
        </template>
        <template #cell(kgInicial)="data">
          <div>
            <div class="font-small-3">
              {{ data.item.kgInicial }}
            </div>
            <div class="font-small-3">
              {{ data.item.cantidadFinalKg }}
            </div>
            <div class="font-small-4 font-weight-bold">
              {{ data.item.cantidadAbasteceKg }}
            </div>
          </div>
        </template>
        <template #cell(galInicial)="data">
          <div>
            <div class="font-small-3">
              {{ data.item.galInicial }}
            </div>
            <div class="font-small-3">
              {{ data.item.cantidadFinalGal }}
            </div>
            <div class="font-small-4 font-weight-bold">
              {{ data.item.cantidadAbasteceGal }}
            </div>
          </div>
        </template>
        <template #cell()="data">
          <span class="d-block text-nowrap text-justify">
            {{ data.value }}
          </span>
        </template>
      </b-table>
    </b-card>
  </b-card>
</template>

<script>
import {
  BTable,
  BCol,
  BCard,
  BCardHeader,
  BMedia,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCard,
    BMedia,
    BCol,
    BCardHeader,
    BTable,
    BSpinner,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        { key: 'idAbastoGranelero', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'fechaOperacion', label: 'Fecha', thClass: ['text-center'], tdClass: ['text-center'] },
        { key: 'flota', label: 'Vehiculo', tdClass: ['text-center'], thClass: ['text-center'] },
        { key: 'ifa', label: 'I/F/A', tdClass: ['text-center', 'bg-white'], thClass: ['text-center'] },
        { key: 'porcInicial', label: 'Porcentaje', tdClass: ['text-center', 'bg-white'], thClass: ['text-center'] },
        { key: 'kgInicial', label: 'Peso KG', tdClass: ['text-center', 'bg-white'], thClass: ['text-center'] },
        { key: 'galInicial', label: 'Peso GAL', tdClass: ['text-center', 'bg-white'], thClass: ['text-center'] },
      ],
      sortBy: null,
    }
  },
  mounted() {
  },
  methods: {
    clean() {
      this.items = []
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header-df {
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}

.nav-tabs {
  background: #f6f6f6 !important;
}

.actions-bar {
  background: #f6f6f6 !important;
  justify-content: end;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

#seccion-content.card > .card-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#empresa{
  width: 100% !important;
}
</style>
