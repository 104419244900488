/* eslint-disable import/prefer-default-export */
import moment from 'moment'

export const getAge = value => {
  const hoy = moment(new Date())
  const fechaString = moment(String(value), 'YYYY-MM-DD').format('MM/DD/YYYY')
  const fecha = moment(fechaString, 'MM/DD/YYYY')
  return `${hoy.diff(moment(String(fecha)).format('MM/DD/YYYY'), 'years')} años`
}

export const formatPeriod = period => {
  // Convertir el periodo en una cadena
  const periodStr = period.toString()

  // Extraer el año y el mes del periodo
  const year = periodStr.substring(0, 4)
  const month = periodStr.substring(4, 6)

  // Array con los nombres de los meses
  const months = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre',
  ]

  return `${months[parseInt(month, 10) - 1]} ${year}`
}
