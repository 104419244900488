<template>
  <div>
    <b-modal
      v-model="modalshow"
      :title="titleModal"
      :hide-footer="true"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      :busy="isBusy"
      :ok-disabled="true"
      body-class="mdlCodeQR"
    >
      <div class="text-center">
        <span class="ml-25 d-block pb-2 text-white"><strong>{{ subTitle }}</strong></span>
        <img
          v-if="srcTicket !== null"
          :src="srcTicket"
          width="400"
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import {
  ref,
  // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import config from '@/services/config'
import { showToast, showError } from '@/helpers'

export default {
  components: {
    BModal,
  },
  directives: {
    Ripple,
  },
  setup() {
    const modalshow = ref(false)
    const isBusy = ref(false)
    const { baseURL } = config
    const pathMultimedia = ref(`${baseURL}api-erpunigas/api/v1/multimedia`)
    const titleModal = ref('Ticket Balanza: ')
    const srcTicket = ref('')
    const subTitle = ref('')

    const openModal = () => {
      if (srcTicket.value !== null) {
        subTitle.value = 'TICKET DE BALANZA'
        srcTicket.value = `${pathMultimedia.value}/byUrl?isthumb=false&url=${srcTicket.value}`
      } else {
        subTitle.value = 'SIN TICKET'
      }
      modalshow.value = true
    }

    return {
      // Customs
      modalshow,
      openModal,
      isBusy,
      pathMultimedia,
      titleModal,
      subTitle,
      srcTicket,
    }
  },
  methods: {
    showToast,
    showError,
  },
}
</script>

<style>
.modal-header{
  align-items: center;
}
.mdlCodeQR.modal-body {
  background-color: #343a40 !important;
  padding-bottom: 60px;
}
</style>
