<template>
  <div>
    <b-modal
      v-model="modalshow"
      :title="titleModal"
      :hide-footer="true"
      no-close-on-backdrop
      no-close-on-esc
      size="xl"
      :busy="isBusy"
      :ok-disabled="true"
      body-class="mdlDistribucion"
    >
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <b-tab active>
          <template #title>
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold"> CILINDROS</span>
            </div>
          </template>
          <acp-cilindros :items="itemsCilindros" />
        </b-tab>
        <b-tab>
          <template #title>
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold"> GRANELEROS</span>
            </div>
          </template>
          <acp-graneleros :items="itemsGraneleros" />
        </b-tab>
        <b-tab>
          <template #title>
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold"> GASOCENTRO</span>
            </div>
          </template>
          <acp-gasocentro :items="itemsGasocentro" />
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BTabs, BTab,
} from 'bootstrap-vue'
import {
  ref,
  // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import config from '@/services/config'
import { showToast, showError } from '@/helpers'
import store from '@/store'
import AcpCilindros from '../cisterna-primaria/AcpCilindros.vue'
import AcpGraneleros from '../cisterna-primaria/AcpGraneleros.vue'
import AcpGasocentro from '../cisterna-primaria/AcpGasocentro.vue'

export default {
  components: {
    BModal,
    BTabs,
    BTab,
    AcpCilindros,
    AcpGraneleros,
    AcpGasocentro,
  },
  directives: {
    Ripple,
  },
  setup() {
    const modalshow = ref(false)
    const isBusy = ref(false)
    const { baseURL } = config
    const pathMultimedia = ref(`${baseURL}api-erpunigas/api/v1/multimedia`)
    const titleModal = ref('Ticket Balanza: ')
    const srcTicket = ref('')
    const subTitle = ref('')
    const dataACP = ref({})
    const itemsCilindros = ref([])
    const itemsGraneleros = ref([])
    const itemsGasocentro = ref([])

    const getDetails = async codigo => {
      await store.dispatch('inventario/ABASTECIMIENTO_FIND_BY_ID', {
        codigo,
      })
        .then(response => {
          if (response) {
            itemsCilindros.value = response.result.cilindros
            itemsGraneleros.value = response.result.granelero
            itemsGasocentro.value = response.result.gasocentro
          }
        })
    }

    const openModal = async () => {
      if (srcTicket.value !== null) {
        subTitle.value = 'TICKET DE BALANZA'
        srcTicket.value = `${pathMultimedia.value}/byUrl?isthumb=false&url=${srcTicket.value}`
      } else {
        subTitle.value = 'SIN TICKET'
      }
      getDetails(dataACP.value.codigo)
      modalshow.value = true
    }

    return {
      // Customs
      modalshow,
      openModal,
      isBusy,
      pathMultimedia,
      titleModal,
      subTitle,
      srcTicket,
      dataACP,
      itemsCilindros,
      itemsGraneleros,
      itemsGasocentro,
    }
  },
  methods: {
    showToast,
    showError,
  },
}
</script>

<style>
.modal-header{
  align-items: center;
}
.mdlDistribucion.modal-body {
  padding-bottom: 60px;
}
</style>
