<template>
  <div style="height: inherit">
    <modal-ticket
      ref="refModalTicket"
    />
    <modal-distribucion
      ref="refModalDistribucion"
    />
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Email List -->
    <div class="email-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchQuery"
              placeholder="Buscar por código; recuerde pulsar ENTER"
              @keyup.enter="updateQuery"
            />
          </b-input-group>
        </div>
      </div>

      <div
        v-if="isBusy"
        class="text-center text-primary my-2"
      >
        <b-spinner
          variant="primary"
          class="align-middle"
        />
        <strong class="ml-1">Cargando...</strong>
      </div>

      <!-- Emails List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
        style="background: white;"
      >
        <ul class="email-media-list">
          <b-media
            v-for="abastecimiento in abastecimientos"
            :key="abastecimiento.idAbastecimientoCisterna"
            tag="li"
            no-body
            class="mail-read"
            style="background: white;"
          >

            <b-media-aside class="media-left mr-50">
              <b-avatar
                rounded
                size="70"
                variant="light"
                src="/assets/img/cisterna.png"
              />
            </b-media-aside>

            <b-media-body>
              <div class="mail-details">
                <div class="mail-items">
                  <h6 class="mb-25">
                    [{{ abastecimiento.codigo }}] {{ abastecimiento.flota.placa }} | {{ abastecimiento.flota.marca }} ({{ abastecimiento.flota.capacidad }} GAL)
                  </h6>
                  <b-badge
                    :variant="getColorEtapa(abastecimiento.etapa)"
                    class="mr-50"
                  >
                    {{ abastecimiento.etapa }}
                  </b-badge>
                  Ingresó: {{ abastecimiento.fechaIngreso }}
                </div>
                <div class="mail-meta-item">
                  <b-button-toolbar justify>
                    <b-button-group size="sm">
                      <b-button
                        v-b-tooltip.hover.top="'Ticket Balanza'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="info"
                        @click="getTicket(abastecimiento)"
                      >
                        <feather-icon icon="ImageIcon" />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top="'Detalle'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="primary"
                        @click="getDetalle(abastecimiento)"
                      >
                        <feather-icon icon="ClipboardIcon" />
                      </b-button>
                    </b-button-group>
                  </b-button-toolbar>
                </div>
              </div>

              <div class="mail-message">
                <b-badge
                  variant="dark"
                  class="mr-50"
                >
                  INICIAL
                </b-badge>
                <b-badge
                  variant="light-dark"
                  class="mr-50"
                >
                  {{ abastecimiento.cantidadInicialKg }} KG
                </b-badge>
                <b-badge
                  variant="light-dark"
                  class="mr-50"
                >
                  {{ abastecimiento.cantidadInicialGal }} GAL
                </b-badge>
                <b-badge
                  variant="light-dark"
                  class="mr-50"
                >
                  {{ abastecimiento.cantidadInicialDiez }} C10
                </b-badge>
                <b-badge
                  variant="dark"
                  class="ml-2 mr-50"
                >
                  ACTUAL
                </b-badge>
                <b-badge
                  variant="light-dark"
                  class="mr-50"
                >
                  {{ abastecimiento.cantidadActualKg }} KG
                </b-badge>
                <b-badge
                  variant="light-dark"
                  class="mr-50"
                >
                  {{ abastecimiento.cantidadActualGal }} GAL
                </b-badge>
                <b-badge
                  variant="light-dark"
                  class="mr-50"
                >
                  {{ abastecimiento.cantidadActualDiez }} C10
                </b-badge>
              </div>
            </b-media-body>
          </b-media>
        </ul>
      </vue-perfect-scrollbar>

      <div class="app-action">
        <div class="action-left">
          <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
        </div>
        <b-pagination
          v-model="page"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          align="center"
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <cisterna-primaria-left-sidebar
        :flotas="flotas"
        :show-email-details="showEmailDetails"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
        @open-form="openForm"
        @apply-filter="applyFilter"
      />
    </portal>
  </div>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import {
  ref, watch,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BButton, VBTooltip, BMedia, BMediaAside, BMediaBody, BAvatar, BButtonToolbar, BButtonGroup, BBadge, BPagination, BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { useNotify } from '@/helpers/toast'
import { capitalizeWords } from '@/helpers/strings'
import { getAge } from '@/helpers/dates'
import CisternaPrimariaLeftSidebar from './CisternaPrimariaLeftSidebar.vue'
import ModalTicket from '../modal/ModalTicket.vue'
import ModalDistribucion from '../modal/ModalDistribucion.vue'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BBadge,
    BPagination,
    BSpinner,

    // 3rd Party
    VuePerfectScrollbar,

    // App SFC
    CisternaPrimariaLeftSidebar,
    ModalTicket,
    ModalDistribucion,
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const flotas = ref([])

    const dataEdit = ref({})
    const dataInfo = ref({})
    const typeForm = ref('')

    const totalRows = ref(0)
    const totalItems = ref(0)
    const totalPages = ref(0)
    const perPage = ref(10)
    const page = ref(1)
    const from = ref(0)
    const to = ref(0)
    const of = ref(0)
    const isBusy = ref(false)
    const pageOptions = ref([10, 20, 50])
    const showEmailDetails = ref(false)
    const ftIdFlota = ref('-1')
    const ftStateEvolution = ref('')
    const ftFecha = ref('')
    const isToggleSidebar = ref(false)
    const formType = ref('')
    const abastecimientos = ref([])

    const searchQuery = ref('')

    const refModalTicket = ref(null)
    const refModalDistribucion = ref(null)

    const showNotify = data => {
      notify(data.title, data.message, data.type)
    }

    const getFlotas = async () => {
      await store.dispatch('inventario/FLOTA_FIND_ALL', {
        tipo: 'list',
        query: '',
        page: 1,
        limit: -1,
        sortBy: 'idFlota%7CASC',
        idTipoFlota: 1,
        condicion: '',
      })
        .then(response => {
          if (response) {
            flotas.value = response
          }
        })
    }

    const showError = error => {
      const msgError = error?.response?.data?.message ? error.response.data.message : 'Ha ocurrido un error'
      notify('Error', msgError, 'danger')
    }

    const getTicket = item => {
      refModalTicket.value.titleModal = `Ticket Balanza: ${item.codigo}`
      refModalTicket.value.srcTicket = item.fotoTicketBalanza
      refModalTicket.value.openModal()
    }

    const getDetalle = async item => {
      refModalDistribucion.value.titleModal = `Detalle Distribución: ${item.codigo}`
      refModalDistribucion.value.srcTicket = item.fotoTicketBalanza
      refModalDistribucion.value.dataACP = item
      refModalDistribucion.value.openModal()
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const listItems = async () => {
      isBusy.value = true
      await store.dispatch('inventario/ABASTECIMIENTO_FIND_ALL', {
        query: searchQuery.value !== undefined ? searchQuery.value : '',
        page: page.value,
        limit: perPage.value,
        sortBy: 'idAbastecimientoCisterna%7CDESC',
        tipo: 'grilla',
        etapa: ftStateEvolution.value,
        fechaIngreso: ftFecha.value,
        idConductor: -1,
        idFlota: ftIdFlota.value,
      })
        .then(response => {
          if (response) {
            totalRows.value = response.totalRows
            totalItems.value = response.totalItems
            totalPages.value = response.totalPage
            from.value = perPage.value * (page.value - 1) + (totalRows.value ? 1 : 0)
            to.value = perPage.value * (page.value - 1) + totalRows.value
            of.value = totalItems.value
            abastecimientos.value = response.items
          }
        })
      isBusy.value = false
    }

    const updateQuery = () => {
      listItems()
    }

    const applyFilter = filters => {
      if (filters.value.idsFlotas !== null && filters.value.idsFlotas.length > 0) {
        ftIdFlota.value = filters.value.idsFlotas.join()
      } else {
        ftIdFlota.value = '-1'
      }
      if (filters.value.fecha !== '') {
        ftFecha.value = filters.value.fecha
      } else {
        ftFecha.value = ''
      }
      listItems()
    }

    const refetchData = response => {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      showNotify({ title: 'Operación Exitosa', message, type: 'success' })
      listItems()
    }

    watch([page], () => {
      listItems()
    })

    getFlotas()
    listItems()

    const openForm = type => {
      formType.value = type
      isToggleSidebar.value = true
    }

    const editEmpresa = item => {
      dataEdit.value = item
      showEmailDetails.value = true
    }

    const getColorEtapa = etapa => {
      if (etapa === 'ABASTECIENDO') {
        return 'success'
      }
      if (etapa === 'PENDIENTE') {
        return 'info'
      }
      return 'light-secondary'
    }

    const deleteRegister = item => {
      Vue.swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('ppptcd/STUDENT_DELETE', {
              id: item.idStudent,
            })
            .then(() => {
              notify('Operación Exitosa', 'El proceso se ha ejecutado satisfactoriamente.', 'success')
              listItems()
            })
            .catch(error => {
              let errorMessage = 'Intentelo en otro momento.'
              if (error.response) {
                errorMessage = error.response.data.message
              }
              notify('Ha ocurrido un error', errorMessage, 'danger')
            })
        }
      })
    }

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      flotas,
      isToggleSidebar,
      formType,
      openForm,
      showError,
      refetchData,
      editEmpresa,
      dataEdit,
      dataInfo,
      typeForm,
      perfectScrollbarSettings,
      showEmailDetails,
      searchQuery,
      filterTags,
      formatDateToMonthShort,
      mqShallShowLeftSidebar,
      totalItems,
      perPage,
      page,
      totalPages,
      totalRows,
      from,
      of,
      to,
      isBusy,
      pageOptions,
      showNotify,
      applyFilter,
      deleteRegister,
      updateQuery,
      abastecimientos,
      getTicket,
      getDetalle,
      capitalizeWords,
      getAge,
      refModalTicket,
      refModalDistribucion,
      getColorEtapa,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";

.per-page-selector {
  width: 90px;
}

.email-application .content-area-wrapper .email-user-list .email-media-list li .avatar img {
  width: 100% !important;
  height: 100% !important;
}

.email-application .content-area-wrapper .email-user-list .email-media-list li .avatar {
  margin-bottom: 0px !important;
}

.email-application .app-action {
    padding: 0.6rem 1.5rem;
}
</style>
