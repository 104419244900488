<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <!-- <div class="form-group-compose text-center compose-btn">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="showEmailDetails"
              variant="primary"
              block
              @click="$emit('open-form', 'new')"
            >
              Agregar Caso
            </b-button>
          </div> -->
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
            style="background: #fcfcff;"
          >
            <h4
              class="section-label mt-1 px-2"
              style="color: #666667; font-size: 0.9rem;"
            >
              Filtros de Búsqueda
            </h4>
            <hr style="border-color: #7367f0;">
            <h6 class="section-label mt-2 mb-1 px-2">
              Fecha de Ingreso
            </h6>
            <b-list-group
              class="list-group-messages"
            >
              <b-list-group-item>
                <flat-pickr
                  id="fecha"
                  v-model="fechaFilter"
                  class="form-control"
                  :config="configDP"
                  placeholder="Seleccione Fecha"
                  @input="applyFilter"
                />
              </b-list-group-item>
            </b-list-group>
            <h6 class="section-label mt-1 mb-1 px-2">
              Cisternas Primarias
            </h6>

            <b-list-group
              class="list-group-messages"
            >
              <b-list-group-item>
                <b-form-checkbox
                  v-for="(option, index) in flotas"
                  :key="`filter-eeii-${index}`"
                  v-model="idsFlotas"
                  :value="option.idFlota"
                  class="mb-1"
                  @change="applyFilter"
                >
                  <span class="align-text-bottom font-small-2">{{ option.placa }}</span>
                </b-form-checkbox>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BListGroup, BListGroupItem, BFormCheckbox,
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    // BButton,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,

    // 3rd Party
    VuePerfectScrollbar,
    flatPickr,
  },
  props: {
    flotas: {
      type: Array,
      required: true,
    },
    showEmailDetails: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    const fechaFilter = ref('')
    const idsFlotas = ref([])
    const filters = ref({})
    filters.value.fecha = ''
    filters.value.idsFlotas = []
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const configDP = ref({
      enableTime: false,
      dateFormat: 'Y-m-d',
      altInput: true,
      altFormat: 'd/m/Y',
    })

    const applyFilter = () => {
      filters.value.fecha = fechaFilter.value
      filters.value.idsFlotas = idsFlotas.value
      emit('apply-filter', filters)
    }

    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,
      idsFlotas,
      applyFilter,
      configDP,
      fechaFilter,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
